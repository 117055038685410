<template>
  <div class="d-flex align-items-center justify-content-center p-4 h-100">
    <changeable-status :state="isSaving" :counter="changeCounter" fixed />

    <div
      class="rounded card d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6 col-xl-5 col-lg-6 col-md-6 col-sm-9"
    >
      <vacancy-header v-if="internalVacancy" :item="internalVacancy" />

      <template v-if="isEnrolling">
        <div
          class="d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6"
        >
          <b-spinner class="text-black" style="opacity: 0.1" />

          <h3 class="text-2xl font-weight-bolder m-0">
            {{ $t("application.confirming_enrollment") }}
          </h3>
          <p class="text-subdued m-0">
            {{ $t("application.we_wish_you_very_luck") }}
          </p>
        </div>
      </template>
      <template v-else>
        <div
          class="d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6"
          v-if="success"
        >
          <CheckCircleIcon size="3.25x" class="icon-success" />

          <h3 class="text-2xl font-weight-bolder m-0">
            {{ $t("application.confirmed_enrollment") }}
          </h3>
          <p class="text-subdued">
            {{ $t("application.you_can_now_do_the_next_steps") }}
          </p>

          <b-button variant="primary" pill block @click="goToApplication">{{
            $t("application.see_next_steps")
          }}</b-button>
          <b-button
            variant="outline-primary"
            pill
            class="border"
            block
            :to="{ name: 'profile-edit' }"
            >{{ $t("application.fill_my_curriculum") }}</b-button
          >
        </div>
        <div
          v-else
          class="d-flex flex-column justify-content-center align-items-center gap-4 h-75 py-10 px-6"
        >
          <div class="d-flex justify-content-center">
            <ExclamationCircleIcon class="text-interactive-danger" size="3x" />
          </div>
          <h3 class="text-2xl font-weight-bolder m-0" v-if="!alreadApplied">
            {{ $t("application.candidacy_failure") }}
          </h3>
          <h3 class="text-2xl font-weight-bolder m-0" v-if="alreadApplied">
            {{ $t("errors.enroll.already-applied") }}
          </h3>
          <div class="align-items-center" v-if="!alreadApplied">
            <span v-if="isEnrollBlocked">{{
              $t("errors.enroll.does-not-attend")
            }}</span>
            <ul class="my-4" :class="{ 'list-unstyled': !isEnrollBlocked }">
              <li v-for="(item, index) in messages" :key="index">
                {{ $t(item) }}
              </li>
            </ul>
            <span v-if="isEnrollBlocked">{{
              $t("errors.enroll.maintain-updated")
            }}</span>

            <div class="divider"><hr class="w-100" /></div>

            <validation-observer ref="enrollStepRules">
              <CompanyForm v-bind="form" v-if="hasFields" />

              <div class="d-flex flex-column gap-2 my-4" v-if="hasPcd">
                <form-set-pcd
                  v-if="sections.personal.pcd.show"
                  v-model="curriculum.personal"
                  :fields="sections.personal.pcd.fields"
                />
              </div>

              <div
                class="d-flex flex-column gap-2 my-4"
                v-if="hasProfessionalSection"
              >
                <h4>
                  {{ $t("curriculum.sections.professional.experiences") }}
                </h4>

                <div class="d-flex justify-content-start gap-2">
                  <form-field-radio
                    name="curriculum.professional.has_professional_history"
                    v-model="curriculum.professional.has_professional_history"
                    :options="domain.domains.has_letter_attribute"
                    autocomplete="off"
                    rules="required"
                  />
                </div>
                <form-set-professional-experience
                  v-if="
                    sections.professional.experiences.show &&
                    curriculum.professional.has_professional_history == 'y'
                  "
                  form-id="express"
                  name="curriculum.experiences"
                  v-model="curriculum.experiences"
                  :fields="sections.professional.experiences.fields"
                  section="experiences"
                />
              </div>
              <div
                class="d-flex flex-column gap-2 my-4"
                v-if="hasAcademicSection"
              >
                <h4>{{ $t("curriculum.sections.education.academic") }}</h4>

                <div class="d-flex justify-content-start gap-2">
                  <form-field-radio
                    name="curriculum.education.has_schooling_level"
                    v-model="curriculum.education.has_schooling_level"
                    :options="domain.domains.has_letter_attribute"
                    autocomplete="off"
                    rules="required"
                  />
                </div>
                <form-set-academic
                  v-if="
                    sections.education.academic.show &&
                    curriculum.education.has_schooling_level == 'y'
                  "
                  name="curriculum.academic"
                  v-model="curriculum.academic"
                  :fields="sections.education.academic.fields"
                  section="academic"
                />
              </div>
              <div
                class="d-flex flex-column gap-2 my-4"
                v-if="hasLanguagesSection"
              >
                <h4>{{ $t("curriculum.sections.education.languages") }}</h4>
                <div class="d-flex justify-content-start gap-2">
                  <form-field-radio
                    name="curriculum.education.has_languages"
                    v-model="curriculum.education.has_languages"
                    :options="domain.domains.has_letter_attribute"
                    autocomplete="off"
                    rules="required"
                  />
                </div>

                <form-set-languages
                  v-if="
                    sections.education.languages.show &&
                    curriculum.education.has_languages == 'y'
                  "
                  name="curriculum.languages"
                  v-model="curriculum.languages"
                  :fields="sections.education.languages.fields"
                  section="languages"
                />
              </div>
              <div
                class="d-flex flex-column gap-2 my-4"
                v-if="hasComputingSection"
              >
                <h4>{{ $t("curriculum.sections.education.computing") }}</h4>
                <div class="d-flex justify-content-start gap-2">
                  <form-field-radio
                    name="curriculum.education.has_computer_knowledge"
                    v-model="curriculum.education.has_computer_knowledge"
                    :options="domain.domains.has_letter_attribute"
                    autocomplete="off"
                    rules="required"
                  />
                </div>

                <form-set-computing
                  v-if="
                    sections.education.computing.show &&
                    curriculum.education.has_computer_knowledge == 'y'
                  "
                  name="curriculum.computing"
                  v-model="curriculum.computing"
                  :fields="sections.education.computing.fields"
                  section="computing"
                />
              </div>
            </validation-observer>

            <b-button variant="primary" pill block @click="continueEnroll()">
              {{ $t("common.complete_curriculum") }}
            </b-button>
          </div>
          <div class="align-items-center" v-else>
            <span v-if="isEnrollBlocked">{{
              $t("errors.enroll.maintain-updated")
            }}</span>

            <div class="divider"><hr class="w-100" /></div>

            <b-button
              variant="primary"
              pill
              block
              :to="{ name: 'vacancy-search' }"
            >
              {{ $t("application.searchForVacancies") }}
            </b-button>
          </div>
          <!--

                <ExclamationCircleIcon class="text-interactive-danger" size="3.25x" />
                <h3 class="text-2xl font-weight-bolder m-0">
                    {{ $t("application.candidacy_failure") }}
                </h3>
                <p class="text-subdued">
                    {{ application_error_message }}
                </p>

                <b-button
                    size="lg"
                    variant="primary"
                    v-if="!application_success"
                    @click="$router.push({ name: 'profile-edit' })"
                >
                    {{ $t("common.complete_curriculum") }}
                </b-button> -->
        </div>
      </template>
    </div>

    <NpsWidget />
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";
import NpsWidget from "@components/help/NpsWidget.vue";
import { CheckCircleIcon, ExclamationCircleIcon } from "@vue-hero-icons/solid";
import CompanyForm from "@blocks/company/Form.vue";
import VacancyHeader from "@blocks/vacancy/VacancyHeader.vue";
import ChangeableStatus from "@components/changeable/Status.vue";
import { ValidationObserver } from "vee-validate";

import { useCompanyForm } from "@state/company/form";
import { useCurriculum } from "@state/user/curriculum";
import { useDomain } from "@state/domain/domain";
import { useApplications } from "@state/user/applications";
import { useVacancies } from "@state/vacancy/vacancies";
import { useChangeable } from "@state/behaviours/changeable";

import * as FormComponents from "@components/forms";
import FormSetPcd from "@components/forms/set/Pcd.vue";
import FormSetProfessionalExperience from "@components/forms/set/ProfessionalExperience.vue";
import FormSetAcademic from "@components/forms/set/Academic.vue";
import FormSetLanguages from "@components/forms/set/Languages.vue";
import FormSetComputing from "@components/forms/set/Computing.vue";

export default {
  mixins: [companyMixin],
  components: {
    ...FormComponents,
    ChangeableStatus,
    VacancyHeader,
    CompanyForm,
    ValidationObserver,
    CheckCircleIcon,
    ExclamationCircleIcon,
    FormSetPcd,
    NpsWidget,
    FormSetProfessionalExperience,
    FormSetAcademic,
    FormSetLanguages,
    FormSetComputing,
    CheckCircleIcon,
    ExclamationCircleIcon,
  },
  props: {
    vacancy_id: Number | String,
  },
  data() {
    return {
      internalVacancy: null,
      isEnrolling: true,
      isLoading: true,
      changeCounter: 0,
      isSaving: false,
      alreadApplied: false,
      stateSubscribe: null,
      sections: {},
      success: false,
      show: false,
      form: {},
      messages: {},
      emittedByZipCodeField: false,
      // applicationId: null,
      // application_success: false,
      // application_error_message: "",
    };
  },
  setup() {
    const curriculum = useCurriculum();
    const domain = useDomain();
    const forms = useCompanyForm();
    const applications = useApplications();
    const vacancies = useVacancies();
    const changeable = useChangeable();

    return {
      vacancies,
      curriculum,
      domain,
      forms,
      applications,
      changeable,
    };
  },
  mounted() {
    this.isEnrolling = true;

    if (this.vacancy_id) {
      this.vacancies.read(this.vacancy_id).then(({ data }) => {
        this.internalVacancy = data;
      });
    }

    if (this.$auth.check()) {
      this.applications
        .enroll(this.vacancy_id)
        .then((response) => {
          if (response.data.success) {
            //this.$root.loading = false;
            this.isEnrolling = false;
            this.success = true;

            setTimeout(() => {
              this.$router.replace({
                path: this.$route.path,
                query: { showNps: true },
              });
            }, 1500);
          } else {
            if (
              _.indexOf(
                response.data.messages,
                "errors.enroll.already-applied"
              ) >= 0
            ) {
              this.alreadApplied = true;
            }

            //this.$root.loading = false;
            this.success = false;
            this.messages = response.data.messages;
            this.form = response.data.form;

            this.stateSubscribe = this.changeable.$subscribe(
              (mutation, state) => {
                const poll_changes = this.changeable.poolChanges("curriculum");
                this.changeCounter = poll_changes.counter;

                if (poll_changes.counter >= 1) {
                  // SAVE PARTIAL CURRICULUM
                  this.isSaving = true;

                  this.curriculum
                    .persist(poll_changes.changes)
                    .then(({ data }) => {
                      if (data.success) {
                        this.changeable.cleanUpAfterSave();
                        this.changeCounter = 0;
                      }
                      this.isSaving = false;
                    });
                }
              }
            );

            if (this.form && _.has(this.form, "fields")) {
              this.forms
                .fields(this.form.name, true, ...this.form.fields)
                .then((fields) => {
                  for (let i in this.forms.curriculum.sections) {
                    this.$set(
                      this.sections,
                      i,
                      this.forms.curriculum.sections[i]
                    );
                  }

                  this.show = true;
                });
            }

            //this.$bvModal.show("modal-vacancy-application");
          }
          this.show = true;
        })
        .finally(() => {
          this.isEnrolling = false;
        });
    } else {
      this.$root.loading = false;
      this.$router.push({
        name: "register1",
        params: { vacancy_id: this.vacancy_id },
      });
    }
  },
  destroyed() {
    if (_.isFunction(this.stateSubscribe)) {
      this.stateSubscribe();
    }
  },
  computed: {
    hasFields() {
      return (
        this.form && _.has(this.form, "fields") && this.form.fields.length > 0
      );
    },
    hasSections() {
      return (
        this.form &&
        _.has(this.form, "sections") &&
        this.form.sections.length > 0
      );
    },
    hasPcd() {
      return (
        _.has(this.form, "sections") && _.includes(this.form.sections, "pcd")
      );
    },
    hasProfessionalSection() {
      return (
        _.has(this.form, "sections") &&
        _.includes(this.form.sections, "professional")
      );
    },
    hasAcademicSection() {
      return (
        _.has(this.form, "sections") &&
        _.includes(this.form.sections, "academic")
      );
    },
    hasComputingSection() {
      return (
        _.has(this.form, "sections") &&
        _.includes(this.form.sections, "computing")
      );
    },
    hasLanguagesSection() {
      return (
        _.has(this.form, "sections") &&
        _.includes(this.form.sections, "languages")
      );
    },
    isEnrollBlocked() {
      return !this.hasFields && !this.hasSections;
    },
    canContinue() {
      return this.success || this.isEnrollBlocked;
    },
  },
  methods: {
    continueEnroll() {
      if (this.canContinue) {
        this.$router.push({
          name: "profile-edit",
        });
      } else {
        this.isEnrolling = true;

        this.$refs.enrollStepRules.validate().then((success) => {
          if (success) {
            const poll_changes = this.changeable.poolChanges("curriculum");
            this.changeCounter = poll_changes.counter;

            // SAVE PARTIAL CURRICULUM
            this.isSaving = true;

            this.curriculum.persist(poll_changes.changes).then(({ data }) => {
              if (data.success) {
                this.changeable.cleanUpAfterSave();
                this.changeCounter = 0;
              }

              this.applications
                .enroll(this.vacancy_id)
                .then((response) => {
                  if (response.data.success) {
                    //this.$root.loading = false;
                    this.isEnrolling = false;
                    this.success = true;

                    setTimeout(() => {
                      this.$router.replace({
                        path: this.$route.path,
                        query: { showNps: true },
                      });
                    }, 1500);
                  } else {
                    if (
                      _.indexOf(
                        response.data.messages,
                        "errors.enroll.already-applied"
                      ) >= 0
                    ) {
                      this.alreadApplied = true;
                    }

                    this.success = false;
                    this.messages = response.data.messages;
                    this.$set(this, "form", response.data.form);
                  }
                })
                .finally(() => {
                  this.isSaving = false;
                  this.isEnrolling = false;
                });
            });
          } else {
            this.inputFocus();
            this.isEnrolling = false;
            return;
          }
        });
      }
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        if(el) {
          el.scrollIntoView();
        }
        return;
      });
    },
    goToApplication() {
      if (this.company.config.sj) {
        this.$router.push({
          name: "application-progress",
          params: {
            application_id: this.vacancy_id,
            sj_id: this.vacancy_id,
          },
        });
      } else {
        this.$router.push({
          name: "application-progress",
          params: { application_id: this.vacancy_id },
        });
      }
    },
  },
  watch: {
    // "curriculum.address.zip_code": function (zipcode) {
    //   this.emittedByZipCodeField = true;
    //   this.curriculum
    //     .setAddressBasedOnZipcode(zipcode)
    //     .then((response) => {
    //       if (response) {
    //         this.$nextTick(() => {
    //           this.curriculum.address.street_type = response.street_type;
    //           this.curriculum.address.street = response.street;
    //           this.curriculum.address.district = response.district;
    //           this.curriculum.address.country = response.country;
    //           this.curriculum.address.state = response.state;
    //           this.curriculum.address.latitude = response.latitude;
    //           this.curriculum.address.longitude = response.longitude;
    //           // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
    //           this.$nextTick(() => {
    //             this.curriculum.address.city = response.city;
    //           });
    //         });
    //       }
    //     })
    //     .finally(() => (this.emittedByZipCodeField = false));
    // },
    "curriculum.address.state": function (uf) {
      if (!this.emittedByZipCodeField) {
        this.curriculum.address.city = "";
      }
      this.domain.load(`cities/${uf}`, "cities");
    },
  },
};
</script>
